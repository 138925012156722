import React  from "react";
import { Helmet } from "react-helmet";
import BenefitsGrid from "../components/BenefitsGrid";
import Layout from "../components/layout";
import * as baseStyles from "./index.module.css";
import * as styles from "./about-us.module.css";
import aboutUsImg from "../images/about-us.jpg";

export default function AboutUs() {
  return (
    <Layout>
      <Helmet>
        <title>About us | MyEZSolutions</title>
      </Helmet>
      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>About us</h1>
          <p>We are rewriting the rules, turning payment processing into business opportunities.<br />
            As a payment technology leader, we are helping entrepreneurs build better businesses, together.</p>
        </div>
        
        <div className={styles.aboutUsParent}>
          <div className={styles.aboutUsLeft}>
            <p>Times have changed, businesses are suffering more than ever trying to make ends meet. We understand the need to cut costs and offer our clients solutions they can count on to make a difference. With our leading technology partner we aim to deliver opportunities for your payment services. We’re here to make it EZ.</p>
          </div>
          <div className={styles.aboutUsRight}>
            <img src={aboutUsImg} alt="About us jpg" width={"100%"}/>
          </div>
        </div>
        
        <BenefitsGrid title={"BUT IT'S MUCH MORE THAN JUST LOWER COSTS"} items={[
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M3 8C2.45 8 2 7.55 2 7S2.45 6 3 6H5.54C5.19 6.6 5 7.29 5 8H3M5 13H2C1.45 13 1 12.55 1 12S1.45 11 2 11H5V13M1 18C.448 18 0 17.55 0 17S.448 16 1 16H5C5 16.71 5.19 17.4 5.54 18H1M21 6H9C7.89 6 7 6.89 7 8V16C7 17.11 7.89 18 9 18H21C22.11 18 23 17.11 23 16V8C23 6.89 22.11 6 21 6M21 12H9V9H21V12Z" /></svg>),
            title: "Same Day Approvals",
            description: "When it comes to servicing your business, we cut through the red tape. We can approve your merchant account the same day."
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" /></svg>),
            title: "Transparent, No Hidden Fees",
            description: "Affordable rates with no hidden fees. Some of our competition doesn't get it. We've made transparency and friendly service our mission."
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z" /></svg>),
            title: "Personalized Service",
            description: "Your business is unique. We don't treat you like a number. Our goal is to provide you with personalized solutions to ensure your ongoing success."
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M4,18V20H8V18H4M4,14V16H14V14H4M10,18V20H14V18H10M16,14V16H20V14H16M16,18V20H20V18H16M2,22V8L7,12V8L12,12V8L17,12L18,2H21L22,12V22H2Z" /></svg>),
            title: "Industry-Leading Technology",
            description: "From leading point-of-sale terminals to online and mobile payment technologies, you can expect top reliability and security."
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z" /></svg>),
            title: "Quality Customer Support",
            description: "Knowledgeable, dependable 24/7 Technical Support. Our help desk can answer your questions day or night, whenever you need assistance."
          }
        ]} />
      </div>
    </Layout>
  );
}